import React, { useContext, useEffect, useState } from "react";
import { CardComponent, HeaderComponent, Sidebar } from "../components";
import { FaRegCopy } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { BsClipboardCheck } from "react-icons/bs";
import { BsHandbag } from "react-icons/bs";
import { BsExclamationTriangle } from "react-icons/bs";
import { BsHouse } from "react-icons/bs";
import { MdChevronRight } from "react-icons/md";
import { BsPieChartFill } from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
//CRAD IMAGES IMPORT
import Image1 from "../assets/Images/icons/share_img-3/share_img.png";
import Image2 from "../assets/Images/icons/share_img-3/DSmall.png";
import Image3 from "../assets/Images/icons/share_img-3/share_img-2.png";
import Image4 from "../assets/Images/icons/share_img-3/share_img-6.png";
import Image5 from "../assets/Images/icons/share_img-3/share_img-7.png";
import Image6 from "../assets/Images/icons/share_img-3/share_img-3.png";
import Image7 from "../assets/Images/icons/share_img-3/share_img-5.png";
import Image8 from "../assets/Images/icons/share_img-3/share_img-4.png";
import { Store } from "../context/Store/Store";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import Loader from "../components/Loader";
import apis from "../context/Services";
import { ethers } from "ethers";

const BackOffice = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const {
    copyToClipboardReferral,
    userContractData,
    userDatabaseData,
    setUserDatabaseData,
    getOneUser,
    icoContractData,
    getAllUserReferrals,
  } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  // const nevigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // console.log(userDatabaseData,"userContractData?.totalReferralShareOfDirectUsers");
  const cardData = [
    {
      title: "My Share",
      value: `${(userContractData?.totaldvtBuy_tokens * 100) / 2500000000}%`,
      refValue: `${Number(+userDatabaseData?.totalReferralShareOfDirectUsers)?.toFixed(6)}%`,
      tokens: `${userContractData?.totaldvtBuy_tokens}`,
      image: Image1,
      color: "#ba4609",
    },
    {
      title: "My Token",
      value: `${userContractData?.dvtWallet_tokens || 0} DVT`,
      image: Image2,
      color: "#ba4609",
    },
    {
      title: "My Ico",
      value: `${icoContractData?.totaldvtBuyICO_tokens || 0} DVT`,
      image: Image3,
      color: "#ba4609",
    },
    {
      title: "Direct Team",
      value: userDatabaseData?.totalDirectUsers,
      icon: BsPieChartFill,
      color: "#ba4609",
      image: Image4,
      path: "/direct-team",
    },
    {
      title: "Direct Business",
      value: `$ ${userDatabaseData?.direactBusiness}`,
      icon: BsPieChartFill,
      color: "#ba4609",
      image: Image5,
    },
    {
      title: "Total Team",
      value: userDatabaseData?.totalChildrensTeam,
      icon: BsPieChartFill,
      color: "#ba4609",
      image: Image6,
      path: "/total-team",
    },
    {
      title: "Referral Income",
      value: `${userDatabaseData?.totalReferralReward}`,
      image: Image2, // New property for image
      color: "#ba4609",
    },
    {
      title: "My Airdrop",
      value: `${userDatabaseData?.totalAirdrop} DVT`,
      icon: BsPieChartFill,
      color: "#ba4609",
      image: Image7, // New property for image
    },
    {
      title: "Swap",
      value: null,
      icon: BsPieChartFill,
      color: "#ba4609",
      image: Image8,
      path: "/swap-dvt"
    },
  ];
  
  // console.log(userDatabaseData?.direactBusiness,"userDatabaseData?.direactBusiness");

  useEffect(() => {
    // const IsConnectedUser = async () => {
    //   if (!isConnected && !address) {
    //       // nevigate("/");
    //   }
    // }
    // IsConnectedUser();
    getOneUser();
    // console.log("DashboardUseEffect");
  }, [address]);

  useEffect(() => {
    getAllUserReferrals();
  }, [address]);

  return (
    <>
      {/* <Loader /> */}
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            // marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
          className="sidebarWidthDeafult"
        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
              <div className="breadcrum">
                <BsHouse className="home" />
                <p>
                  Home <MdChevronRight />
                  Back
                </p>
              </div>
              <div className="card-con">
                {cardData.map((card, index) => (
                  <Link to={card.path} key={index} className="card-link">
                    <CardComponent
                      key={index}
                      icon={card?.icon}
                      image={card?.image}
                      color={card?.color}
                      title={card?.title}
                      value={card?.value}
                      tokens={card?.tokens}
                      refValue={card?.refValue}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackOffice;
