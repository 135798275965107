import React, { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Store } from "../context/Store/Store";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import apis from "../context/Services";
import { toast } from "react-toastify";

const ActionConfirmations = () => {

  let siteKeys = process.env.REACT_APP_SITE_KEYS




  const { showConfirmationModal, setShowConfirmationModal, referraAddress } = useContext(Store);
 
  const nevigate = useNavigate();

  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [captchaValidated, setCaptchaValidated] = useState(false);

  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaValidated(true); // Captcha is valid
  };

  const handleSubmit = () => {
    if (!captchaValidated) {
      toast.error("Please confirm you're not a robot!");
      return;
    }
    handAgree();
  };

  const handAgree = async () => {
    try {
      let data = {
        wallet_address: address?.toString(),
        parent_wallet_address: referraAddress?.toString(),
      };
      const response = await apis.connectWallet(data);
      if (response?.status) {
        nevigate("/dashboard");
        // setShowConfirmationModal(false);
      }
    } catch (error) {
      // nevigate("/dashboard")
      console.error("Error connecting user:", error.message);
    }
  };


  return (
    <div className="confirmation-page">
      <p>You Are Referred By</p>
      <h1> {referraAddress?.slice(0,6)} ........ {referraAddress?.slice(-6)}</h1>
      <p>Are you sure you want to continue?</p>

      {/* reCAPTCHA Component */}
      <div className="captcha-wrap">
        <ReCAPTCHA
          sitekey={siteKeys}
          onChange={onCaptchaChange}
        />
      </div>

      <div className="btn-wrap">
        <button className="sure" onClick={()=>handleSubmit()}>
          Sure
        </button>
        <button className="sure">Cancel</button>
      </div>
    </div>
  );
};

export default ActionConfirmations;
