import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "./HeaderComp";
import { BsHouse } from "react-icons/bs";
import { MdChevronRight } from "react-icons/md";
import Sidebar from "./Sidebar";
import { Store } from "../context/Store/Store";
import { useLocation } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import apis from "../context/Services";
import { ethers } from "ethers";

const DirectTeamComp = () => {
  const { userDatabaseData, setUserDatabaseData, getUserWithParents } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log(userDatabaseData, "userDatabaseDatauserDatabaseData");

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Use useLocation to get the current path
  const location = useLocation();
  // Determine which data to show based on the route
  const dataToDisplay = userDatabaseData?.DirectUsersData;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    getUserWithParents();
  }, [address, location]);

  // console.log(dataToDisplay, "dataToDisplaydataToDisplaydataToDisplay");
  return (
    <>
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            // marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
          className="sidebarWidthDeafult"

        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
              <div className="breadcrum">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Wallet Address</th>
                        <th>Business</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataToDisplay?.length > 0 ? 
                      dataToDisplay?.map((row, index) => (
                        <tr key={index}>
                          {/* Displaying username */}
                          <td>{row?.user_name || "N/A"}</td>

                          {/* Displaying email */}
                          <td>{row?.email || "N/A"}</td>

                          {/* Displaying phone */}
                          <td>{row?.phone_number || "N/A"}</td>

                          {/* Displaying wallet address */}
                          <td>
                            {`${row?.wallet_address?.slice(
                              0,
                              6
                            )}....${row?.wallet_address?.slice(-6)}` || "N/A"}
                          </td>
                          {/* Displaying buy amount */}
                          <td>
                            {`${row?.buyTokens} / ${row?.usdtAmount}` || "0"}
                          </td>
                        </tr>
                      )): (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {/* <table>
                    <thead>
                      <tr>
                        {headers?.map((header) => (
                          <th key={header}>
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataToDisplay?.map((row, index) => (
                        <tr key={index}>
                          {headers?.map((header) => (
                            <td key={header}>{row[header]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectTeamComp;
