import React, { useContext, useState } from "react";
import * as XLSX from "xlsx";
import { ethers } from "ethers";
import "../assets/CSS/airdrop.css";
import { Store } from "../context/Store/Store";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const AdminAirdrop = () => {
  const [wallets, setWallets] = useState([]);
  const [amount, setAmount] = useState("");
  const { HandleAirDropTokens, loader } = useContext(Store);

  // Function to handle file upload and parsing
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet contains the data
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      // Parse the sheet data
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // First column is wallet addresses, second column is amounts
      const addresses = parsedData.map((row) => row[0]);

      setWallets(addresses);
    };

    reader.readAsArrayBuffer(file);
  };

  // Function to handle sending data to the smart contract
  const handleAirdrop = async () => {
    if (!wallets.length || !amount) {
      alert("Please provide both wallets and an amount");
      return;
    }
    // console.log(wallets, "wallets");
    // console.log(amount, "amount");
    try {
      // Send transaction to the smart contract
      await HandleAirDropTokens(wallets, ethers.utils.parseEther(amount));
    } catch (err) {
      console.error("Error sending airdrop:", err);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="admin-airdrop">
          <div className="admin-airdrop-card">
            <h2 className="airdrop-title">Admin Airdrop Section</h2>
            <input
              className="airdrop-file-input"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
            <input
              className="airdrop-amount-input"
              type="text"
              placeholder="Enter token amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            {/* Centered button using a flex container */}
            <div className="airdrop-button-container">
              <button
                className="airdrop-submit-button"
                onClick={() => handleAirdrop()}
              >
                Send Airdrop
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminAirdrop;
