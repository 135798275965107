import React, { useContext, useState } from "react";
import { HeaderComponent, Sidebar } from "../../components";
import Image3 from "../../assets/Images/icons/share_img-3/share_img-2.png";
import { Store } from "../../context/Store/Store";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import apis from "../../context/Services";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const BuyDVT = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { BuyWithUSDTandUSDC, loader } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { userDatabaseData, userContractData } = useContext(Store);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleBuyFuntion = async (planBuy) => {
    if (!userDatabaseData?.isProfileCompleted) {
      return toast.error(
        "Please Completed Your Profile by Go To Profile Section"
      );
    }
    if (address && isConnected) {
      try {
        let listAddress = await apis.getPlanBuyingReferrals(address?.toString());
        // console.log(
        //   listAddress?.data?.parentAddresses,
        //   "listAddresslistAddresslistAddress`"
        // );
        // console.log(
        //   listAddress?.data?.parentAddresses.length,
        //   "listAddress?.data?.lengtg"
        // );
        // console.log(planBuy, "planBuyplanBuyplanBuyplanBuyplanBuy`");
        BuyWithUSDTandUSDC(listAddress?.data?.parentAddresses, planBuy);
      } catch (error) {
        console.log(error, "error");
        toast.error("get api fail", error);
      }
    } else {
      toast.error("Please connect your walllet");
    }
  };

  // console.log(
  //   userContractData?.smartContractPlans,
  //   "userContractData?.smartContractPlans"
  // );
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div style={{ display: "flex" }} className="backOffice">
          {/* Sidebar */}
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />

          {/* Main Content Area */}
          <div
            style={{
              // marginLeft: isSidebarOpen ? "250px" : "80px",
              width: "100%",
              transition: "margin-left 0.3s ease",
            }}
             className="sidebarWidthDeafult"
          >
            <HeaderComponent isSidebarOpen={isSidebarOpen} />

            <div className="content">
              <div style={{ padding: "20px", paddingTop: "100px" }}>
                <div className="main-cardd-buyDvt">
                  <div className="top">
                    <h3>DVT PLANS</h3>
                  </div>
                  <div className="cardd-dvt-con">
                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />
                      <div className="plan-heading">
                        <h4>0.1% Share</h4>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>Share Type</h2>
                        <p>0.1%</p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>DVT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[0]?.tokenAmount}
                        </p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>USDT Amount</h2>
                        {/* <p>3000.00</p> */}
                        <p>
                          {userContractData?.smartContractPlans[0]?.usdtAmount}
                        </p>
                      </div>
                      <button onClick={() => handleBuyFuntion(0)}>
                        Buy Now
                      </button>
                    </div>

                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />
                      <div className="plan-heading">
                        <h4>0.05% Share</h4>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>Share Type</h2>
                        <p>0.05%</p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>DVT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[1]?.tokenAmount}
                        </p>
                        {/* <p>12,50,000.00</p> */}
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>USDT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[1]?.usdtAmount}
                        </p>
                        {/* <p>1500.00</p> */}
                      </div>
                      <button onClick={() => handleBuyFuntion(1)}>
                        Buy Now
                      </button>
                    </div>

                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />
                      <div className="plan-heading">
                        <h4>0.025% Share</h4>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>Share Type</h2>
                        <p>0.025%</p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>DVT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[2]?.tokenAmount}
                        </p>
                        {/* <p>6,25000.00</p> */}
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>USDT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[2]?.usdtAmount}
                        </p>
                        {/* <p>750.00</p> */}
                      </div>
                      <button onClick={() => handleBuyFuntion(2)}>
                        Buy Now
                      </button>
                    </div>

                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />
                      <div className="plan-heading">
                        <h4>0.0125% Share</h4>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>Share Type</h2>
                        <p>0.0125%</p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>DVT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[3]?.tokenAmount}
                        </p>
                        {/* <p>3,12,500.00</p> */}
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>USDT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[3]?.usdtAmount}
                        </p>
                        {/* <p>375.00</p> */}
                      </div>
                      <button onClick={() => handleBuyFuntion(3)}>
                        Buy Now
                      </button>
                    </div>

                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />
                      <div className="plan-heading">
                        <h4>0.00625% Share</h4>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>Share Type</h2>
                        <p>0.00625%</p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>DVT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[4]?.tokenAmount}
                        </p>
                        {/* <p>1,56,250.00</p> */}
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>USDT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[4]?.usdtAmount}
                        </p>
                        {/* <p>188</p> */}
                      </div>
                      <button onClick={() => handleBuyFuntion(4)}>
                        Buy Now
                      </button>
                    </div>

                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />
                      <div className="plan-heading">
                        <h4>0.003125% Share</h4>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>Share Type</h2>
                        <p>0.003125%</p>
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>DVT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[5]?.tokenAmount}
                        </p>
                        {/* <p>78,125.00</p> */}
                      </div>
                      <div className="wrap-content-dvt">
                        <h2>USDT Amount</h2>
                        <p>
                          {userContractData?.smartContractPlans[5]?.usdtAmount}
                        </p>
                        {/* <p>94</p> */}
                      </div>
                      <button onClick={() => handleBuyFuntion(5)}>
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyDVT;
