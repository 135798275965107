import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "./HeaderComp";
import Sidebar from "./Sidebar";
import { Store } from "../context/Store/Store";
import { useLocation } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const TotalTeamComp = () => {
  const { userDatabaseData, setUserDatabaseData, getUserWithParents } =
    useContext(Store);
  const { address } = useWeb3ModalAccount();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Use useLocation to get the current path
  const location = useLocation();

  // Determine which data to show based on the route
  const dataToDisplay = userDatabaseData?.TeamUsersData;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    getUserWithParents();
  }, [address, location]);

  // Logic to adjust the displayed level for users (relative to the starting level)
  const startingLevel = dataToDisplay?.[0]?.level - 1 || 2; // First user's level
  const adjustedLevel = (userLevel) => userLevel - startingLevel + 1; // Display relative levels

  return (
    <>
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            // marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
           className="sidebarWidthDeafult"
        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
              <div className="breadcrum">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Wallet Address</th>
                        <th>Level</th>
                        <th></th>
                        <th>Business</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataToDisplay?.length > 0 ? (
                        dataToDisplay?.map((row, index) => (
                          <tr key={index}>
                            {/* Displaying username */}
                            <td>{row?.user_name || "N/A"}</td>

                            {/* Displaying wallet address */}
                            <td>
                              {`${row?.wallet_address?.slice(
                                0,
                                6
                              )}....${row?.wallet_address?.slice(-6)}` || "N/A"}
                            </td>

                            {/* Displaying the adjusted level */}
                            <td>{adjustedLevel(row?.level) || "N/A"}</td>
                            <td></td>

                            {/* Displaying buy amount */}
                            <td>
                              {`${row?.buyTokens} / ${row?.usdtAmount}` || "0"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalTeamComp;
