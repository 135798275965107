import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import "./modal.css";
import { Store } from "../../context/Store/Store";
import apis from "../../context/Services";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const ConfirmationModal = () => {
  const { showConfirmationModal, setShowConfirmationModal, referraAddress } = useContext(Store);
  const nevigate = useNavigate();
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const handAgree = async () => {
    try {
      let data = {
        wallet_address: address?.toString(),
        parent_wallet_address: referraAddress?.toString(),
      };
      const response = await apis.connectWallet(data);
      if (response.status) {
        nevigate("/dashboard");
        setShowConfirmationModal(false);
      }
    } catch (error) {
      // nevigate("/dashboard")
      console.error("Error connecting user:", error.message);
    }
  };

  return (
    <Modal
      show={showConfirmationModal}
      onHide={() => setShowConfirmationModal(false)}
      centered
      size="lg"
      className="confirmationModal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>You Are Referred By</p>
        <h4>{referraAddress}</h4>
        <p>Are you sure you want to continue?</p>
        <div className="btn-wrap">
          <button className="sure" onClick={() => handAgree()}>
            Sure
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
