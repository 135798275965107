import React from "react";
import { BsPieChartFill } from "react-icons/bs"; // You can import different icons here based on your needs.

const CardComponent = ({
  icon: Icon,
  refValue,
  color,
  tokens,
  title,
  value,
  image,
}) => {
  return (
    <div className="cardd">
      <div className="top">
        {image ? (
          <img
            src={image}
            alt={title}
            style={{ width: "40px", height: "40px" }}
          />
        ) : (
          <Icon color={color} />
        )}
      </div>
      <div className="bottom">
        <h3>{title}</h3>
        {value && <p>{value}</p>}
      </div>
      {tokens && (
        <div className="bottom">
          <h3>Value</h3>
          {tokens && <p>{tokens}</p>}
        </div>
      )}
      {refValue && (
        <div className="bottom">
          <h3>My Referral Share</h3>
          {refValue && <p>{refValue}</p>}
        </div>
      )}
    </div>
  );
};

export default CardComponent;
