import React, { useContext, useState } from "react";
import { HeaderComponent, Sidebar } from "../../components";
import { Store } from "../../context/Store/Store";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const SwapDVT = () => {
  const { swapDoofyTokens, icoContractData, loader, userDatabaseData, userContractData } = useContext(Store);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [dvtAmount, setDvtAmount] = useState(0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSwapDoofy = async (_amount) => {
    try {
      if (!userDatabaseData?.isProfileCompleted) {
        return toast.error(
          "Please Completed Your Profile by Go To Profile Section"
        );
      }
      await swapDoofyTokens(_amount);
      setDvtAmount(0);
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            // marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
          className="sidebarWidthDeafult"

        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
              <h1 className="buy-ico">SWAP DVT</h1>
              <div className="finance-buy-send-card">
                <div className="send-recieved-con">
                  <h2>Sending Amount</h2>
                  {/* <h2>DVT</h2> */}
                  <div className="text-wrap">
                    <h3>{dvtAmount}</h3>
                    {/* <h3>DVT</h3> */}
                    <img
                      src="/assets/images/DSmall.png"
                      alt="USDT"
                      width={35}
                    />
                  </div>
                </div>
                <div className="send-recieved-con">
                  <h2>Recieved Amount</h2>
                  {/* <h2>USDT</h2> */}
                  <div className="text-wrap">
                    {/* <h3>USDT</h3> */}
                    <h3> {dvtAmount * +icoContractData?.swapPrice} </h3>
                    <img src="/assets/images/USDT.png" alt="USDT" width={35} />
                  </div>
                </div>
                <div className="send-recieved-con">
                  <h2>Account Balance</h2>
                  <div className="text-wrap">
                    <h3> {userContractData?.dvtWallet_tokens || 0} </h3>
                    <img
                      src="/assets/images/DSmall.png"
                      alt="USDT"
                      width={35}
                    />
                  </div>
                </div>
                <div className="input-con">
                  <h2>Swap DVT Amount</h2>
                  <div className="wrap">
                    {/* <span>DVT</span> */}
                    <img
                      src="/assets/images/DSmall.png"
                      alt="USDT"
                      width={35}
                    />
                    <input
                      type="number"
                      name=""
                      id=""
                      onChange={(e) => setDvtAmount(e.target.value)}
                      placeholder="Amount in DVT"
                    />
                  </div>
                </div>
                <div className="price-con">
                  <h2>1DVT ={icoContractData?.swapPrice} USDT</h2>
                  <p>The receivable amount is {dvtAmount * +icoContractData?.swapPrice} USDT</p>
                </div>
                <div className="btn-wrap">
                  <button onClick={() => handleSwapDoofy(dvtAmount)}>
                    SWAP
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default SwapDVT;
