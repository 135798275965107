import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import "./modal.css";
import apis from "../../context/Services";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { Store } from "../../context/Store/Store";
import { isValidNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const UpdateProfileModal = ({ updateProfileModal, setUpdateProfileModal }) => {
  const { getOneUser } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  // State variables to hold form inputs
  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    phone_number: "", // Use 'phone_number' if that's the field name in your backend
  });

  // State for handling success and error messages
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, 'IN'); // Use 'IN' for India default
    return phoneNumberObj && phoneNumberObj.isValid();
  };

  // Handle input changes
  const handleChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
     // Validate phone number
  if (!validatePhoneNumber(formData.phone_number)) {
    setError("Invalid phone number.");
    return;
  }
    try {
      if(!address && !isConnected) return;
      // Call the updateUserInfo API
      const response = await apis.updateUserInfo(address, formData);
      // console.log("User info updated successfully:", response.data);
      setMessage("Profile updated successfully!");
      setError("");
      // Optionally, you can refresh user data here or update context/state
      // Close the modal after a delay
      setTimeout(() => {
        setUpdateProfileModal(false);
        setMessage("");
        getOneUser();
      }, 2000);
    } catch (err) {
      console.error("Error updating user info:", err.message);
      setError(err.message || "An error occurred while updating your profile.");
    }
  };

  return (
    <Modal
      show={updateProfileModal}
      onHide={() => setUpdateProfileModal(false)}
      centered
      size="lg"
      className="updateProfileModal"
    >
      <Modal.Header closeButton>
        <h3>Update Profile</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="user_name">Enter Name</label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              placeholder="Enter your name"
              value={formData.user_name}
              onChange={(e) => handleChange(e.target.value, 'user_name')}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Enter Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={(e) => handleChange(e.target.value, 'email')}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone_number">Enter Phone</label>
            <PhoneInput
              international
              defaultCountry="IN" // Set default country to India
              value={formData.phone_number}
              onChange={(value) => handleChange(value, 'phone_number')}
              placeholder="Enter your phone number"
            />
          </div>
          <div className="btn-wrapper">
            <button type="submit">Submit</button>
          </div>
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProfileModal;
