import axios from "axios";

const createBackendServer = (baseURL) => {
  const api = axios.create({
    baseURL: `${baseURL}/api/`,
    withCredentials: false,
    headers: {
      Accept: "application/json",
    },
    timeout: 60 * 1000,
  });

  //Interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data?.message;
      error.message = message ?? error.message;
      if (error?.response?.data?.errors)
        error.errors = error?.response?.data?.errors;
      return Promise.reject(error);
    }
  );

  const connectWallet = async (body) => await api.post(`connectWallet`, body);
  const getAllUserReferrals = async (params) => await api.get(`getAllUserReferrals/${params}`);
  const getUserWithParents = async (params) => await api.get(`getUserWithParents/${params}`);
  const getOneUser = async (params) => await api.get(`getUserData/${params}`);
  const getPlanBuyingReferrals = async (params) => await api.get(`getPlanBuyingReferrals/${params}`);
  const getUserEventsHistory = async (params) => await api.get(`getUserEventsHistory/${params}`);
  const updateUserInfo = async (params, body) => await api.put(`updateUserInfo/${params}`, body);
  const createProfilePicture = async (params, body) => await api.post(`createProfilePicture/${params}`, body);
  
  const sendEmailAPI = async (body) => await api.post(`sendEmailAPI`, body);

  return {
    sendEmailAPI,
    getUserEventsHistory,
    updateUserInfo,
    createProfilePicture,
    getPlanBuyingReferrals,
    getUserWithParents,
    getAllUserReferrals,
    connectWallet,
    getOneUser,
  };
};

// const apis = createBackendServer("http://localhost:5000"); //TODO:change
// const apis = createBackendServer("https://tech.oofyventures.com"); //TODO:change
const apis = createBackendServer("https://dev.doofyventures.com");

export default apis;
