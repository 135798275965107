import React, { useContext, useEffect, useState } from "react";
import { HeaderComponent, Sidebar } from "../../components";
import Image3 from "../../assets/Images/icons/share_img-3/share_img-2.png";

import { Store } from "../../context/Store/Store";
import Countdown from "react-countdown";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import Loader from "../../components/Loader";

const ClaimDVT = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const {
    userContractData,
    claimAirdropRecivedTokens,
    GetAirdropContractValues,
    airdropContractData,
    GetValues,
    claimICOBuyedTokens,
    icoContractData,
    loader,
    claimTokens,
  } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [countDown, setCountDown] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p>Completed</p>;
    } else {
      // Render a countdown
      return (
        <span style={{ color: "white" }}>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  useEffect(() => {
    GetAirdropContractValues();
  }, [address]);

  // useEffect(() => {
  //   setCountDown(userContractData?.remainTimeInClaim);
  // }, [address, userContractData?.remainTimeInClaim]);

  // const date = new Date(countDown);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div style={{ display: "flex" }} className="backOffice">
          {/* Sidebar */}
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />

          {/* Main Content Area */}
          <div
            style={{
              // marginLeft: isSidebarOpen ? "250px" : "80px",
              width: "100%",
              transition: "margin-left 0.3s ease",
            }}
          className="sidebarWidthDeafult"

          >
            <HeaderComponent isSidebarOpen={isSidebarOpen} />

            <div className="content">
              <div style={{ padding: "20px", paddingTop: "100px" }}>
                <div className="main-cardd-buyDvt">
                  <div className="top">
                    <h3> CLAIM </h3>
                  </div>
                  <div className="cardd-dvt-con">
                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />

                      <div className="plan-heading">
                        <h4>Locked Share Value</h4>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Total Share Buy</h2>
                        <p>{userContractData?.totaldvtBuy_tokens}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Locked DVT Amount</h2>
                        <p>{userContractData?.dvtBuy_tokens}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>To Be Released DVT</h2>
                        <p>
                          {Number(+userContractData?.dvtBuy_tokens * 0.02)?.toFixed(2)}
                        </p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Next Claim In</h2>
                        <Countdown date={new Date(userContractData?.remainTimeInClaim)} renderer={renderer} />
                        {/* <p> Pending </p> */}
                      </div>

                      <button
                        // style={{
                        //   backgroundColor: "#218838", // Always dark shade
                        //   color: "#FFFFFF", // White text color
                        //   border: "none", // Remove default border
                        //   borderRadius: "4px", // Rounded corners
                        //   padding: "10px 20px", // Padding
                        //   cursor: true ? "not-allowed" : "pointer", // Cursor style
                        //   opacity: true ? "0.6" : "1", // Opacity for disabled state
                        //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow for dark theme
                        //   transition:
                        //     "background-color 0.3s, box-shadow 0.3s, opacity 0.3s", // Smooth transition
                        //   fontSize: "16px", // Font size
                        // }}
                        // disabled={true}
                        onClick={() => claimTokens()}
                      >
                        Claim Now
                      </button>
                    </div>

                    {/* //////////////////////////////////// ICO  ////////////////////////// */}

                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />

                      <div className="plan-heading">
                        <h4>Locked ICO Tokens</h4>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Total ICO Buy</h2>
                        <p>{icoContractData?.totaldvtBuyICO_tokens}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Locked DVT Amount In ICO</h2>
                        <p>{icoContractData?.dvtBuyICO_tokens}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>To Be Released DVT</h2>
                        <p>
                          {Number(
                            +icoContractData?.dvtBuyICO_tokens * 0.25
                          )?.toFixed(2)}
                        </p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Next Claim In</h2>
                        <Countdown date={new Date(icoContractData?.remainTimeInICOClaim)} renderer={renderer} />
                        {/* <p> Pending </p> */}
                        {/* <p> completed </p> */}
                      </div>

                      {/* <button
                        style={{
                          backgroundColor: "#218838", // Always dark shade
                          color: "#FFFFFF", // White text color
                          border: "none", // Remove default border
                          borderRadius: "4px", // Rounded corners
                          padding: "10px 20px", // Padding
                          cursor: true ? "not-allowed" : "pointer", // Cursor style
                          opacity: true ? "0.6" : "1", // Opacity for disabled state
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow for dark theme
                          transition:
                            "background-color 0.3s, box-shadow 0.3s, opacity 0.3s", // Smooth transition
                          fontSize: "16px", // Font size
                        }}
                        disabled={true}
                        onClick={() => claimTokens()}
                      >  */}

                      <button onClick={() => claimICOBuyedTokens()}>
                        Claim Now
                      </button>
                    </div>

                    {/* //////////////////////////////////// Airdrop  ////////////////////////// */}
                    {/* claimAirdropRecivedTokens
                    airdropContractData */}
                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />

                      <div className="plan-heading">
                        <h4>Locked Airdrop Tokens</h4>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Total Airdrop Win</h2>
                        <p>{airdropContractData?.totalRecivedAmount}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Locked DVT Amount In Airdrop</h2>
                        <p>{airdropContractData?.remainBalance}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>To Be Released DVT</h2>
                        {/*TODO:: Change Claim Rate*/}
                        <p>
                          {Number(
                            +airdropContractData?.remainBalance * 0.25
                          )?.toFixed(2)}
                        </p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Next Claim In</h2>
                        <Countdown date={new Date(airdropContractData?.remainTimeInAirdropClaim)} renderer={renderer} />
                        {/* <p> Pending </p> */}
                        {/* <p> completed </p> */}
                      </div>

                      {/* <button
                        style={{
                          backgroundColor: "#218838", // Always dark shade
                          color: "#FFFFFF", // White text color
                          border: "none", // Remove default border
                          borderRadius: "4px", // Rounded corners
                          padding: "10px 20px", // Padding
                          cursor: true ? "not-allowed" : "pointer", // Cursor style
                          opacity: true ? "0.6" : "1", // Opacity for disabled state
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow for dark theme
                          transition:
                            "background-color 0.3s, box-shadow 0.3s, opacity 0.3s", // Smooth transition
                          fontSize: "16px", // Font size
                        }}
                        disabled={true}
                        onClick={() => claimTokens()}
                      > */}

                      <button onClick={() => claimAirdropRecivedTokens()}>
                        Claim Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClaimDVT;
