import React, { useContext, useEffect, useState } from "react";
import { HeaderComponent, Sidebar } from "../../components";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { Store } from "../../context/Store/Store";

const IcoHistory = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { getUserEventsHistory, userHistoryData } = useContext(Store);
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    const { address, chainId, isConnected } = useWeb3ModalAccount();
  
    useEffect(() => {
      getUserEventsHistory();
    }, [address]);
  return (
    <>
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            // marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
          className="sidebarWidthDeafult"

        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
            <div className="breadcrum">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Share Type</th>
                        <th>Buy DVT</th>
                        <th>In USDT</th>
                        <th>Date</th>
                        {/* <th>Wallet Address</th> */}
                        {/* <th>Level</th> */}
                      </tr>
                    </thead>
                    <tbody>
                    {userHistoryData?.buyHistory?.length > 0 ? (
                        userHistoryData?.buyHistory?.map((row, index) => (
                          <tr key={index}>
                            {/* Displaying wallet address */}
                            <td>
                              {`${row?.buyer_address?.slice(
                                0,
                                6
                              )}....${row?.buyer_address?.slice(-6)}` || "N/A"}
                            </td>
                            {/* `${userContractData?.dvtBuy_tokens * 100 / 600000000}%`, */}
                            {/* Displaying buy amount */}
                            <td>{`${row?.tokenAmount * 100  / 2500000000}%` || "N/A"}</td>

                            {/* Displaying buy amount */}
                            <td>{row?.tokenAmount || "0"}</td>

                            {/* Displaying buy amount */}
                            <td>{row?.usdtAmount || "0"}</td>

                            {/* Displaying date */}
                            <td>{new Date(row?.createdAt).toLocaleDateString() || "N/A"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IcoHistory;
