'use client'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

// 1. Get projectId
const projectId = '48d44678afcba02c797f5d30369c89a7'

// // // 2. Set chains
// const mainnet = {
//   chainId: 1,
//   name: 'Ethereum',
//   currency: 'ETH',
//   explorerUrl: 'https://etherscan.io',
//   rpcUrl: `${process.env.REACT_PUBLIC_RPC_URL_ETH}`
// }

const Binance = {
  chainId: 56,
  name: 'Binance',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: `${process.env.REACT_APP_RPC_URL_BNB}` //TODO::change network
}

// const BNBSmartChainTestnet = {
//   chainId: 97,
//   name: 'Binance',
//   currency: 'BNB',
//   explorerUrl: 'https://testnet.bscscan.com',
//   rpcUrl: `${process.env.REACT_APP_RPC_URL_BNB}` //TODO::change network
// }

// const sepoiliaTestnet = {
//   chainId: 11155111,
//   name: 'Sepolia',
//   currency: 'ETH',
//   explorerUrl: 'https://sepolia.etherscan.io',
//   rpcUrl: `${process.env.NEXT_PUBLIC_RPC_URL_ETH}`
// }


// 3. Create a metadata object
const metadata = {
  name: 'Doofy Ventures',
  description: 'This is Doofy Ventures',
  url: 'https://doofyventures.com/', // origin must match your domain & subdomain
  icons: ['https://doofyventures.com/images/logo.png']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  //rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 97 // used for the Coinbase SDK
})

// 5. Create a AppKit instance
createWeb3Modal({
  ethersConfig,
  chains: [Binance],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


export function Web3Modal({ children }) {
  return children
}